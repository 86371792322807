<template>
	<div v-if="isAuthorized">
		<slot></slot>
	</div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useUserStore } from '../../../stores/userStore';

const UserStore = useUserStore();

const isAuthorized = computed(() => ['admin', 'manager'].includes(UserStore.userRole));
</script>
